@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&family=Mulish:wght@400;700&family=Teko:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Helvetica",
    "Verdana", "sans-serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, "Montserrat", monospace;
}

:root {
  --a2-color-indigo: #450389;
  --font-teko: "Teko", sans-serif;
  --font-mulish: "Mulish", sans-serif;
  --font-inter: "Inter", sans-serif;
}
